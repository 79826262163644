import axios from '@/libs/axios'

class PostLesson {
  static list(params) {
    return axios.post('admin/teacher/post-lessons', params)
  }

  static update(params) {
    return axios.post('admin/teacher/post-lessons/update', params)
  }
}

export default PostLesson
