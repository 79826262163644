<template>
  <div>
    <!-- Filters -->
    <shared-post-lesson-filters
      :status-options="statusOptions"
      :date-filter.sync="filter.date"
      :status-filter.sync="filter.status"
      :content-name-filter.sync="filter.content_name"
      @click="loadData"
    />
    <!-- Table Container Card -->
    <b-overlay
      :show="loading"
      opacity="0.10"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <b-table
          responsive
          :items="items"
          :fields="fields"
          class="mb-0"
        >

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-link
              href="#"
              class="card-link btn btn-primary"
              @click="viewLesson(data.item)"
            >
              View
            </b-link>
          </template>

        </b-table>
        <template slot="footer">
          <b-pagination
            v-model="filter.page"
            :per-page="perPage"
            hide-goto-end-buttons
            :total-rows="totalPage"
            align="center"
            @change="loadData"
          />
        </template>
      </b-card>
    </b-overlay>

    <b-modal
      ref="view-post-lesson-modal"
      cancel-variant="outline-secondary"
      ok-title="Update"
      cancel-title="Close"
      centered
      no-close-on-backdrop
      size="lg"
      title="View Post-Lesson"
      @ok="postLessonHandle"
      @close="postLessonResetFormData"
      @cancel="postLessonResetFormData"
    >
      <validation-observer ref="simpleRules">
        <b-form @submit.stop.prevent="postLessonHandleSubmit">
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <label>{{ $t('question') }}</label>
                <textarea
                  v-model="postLessonFormData.question"
                  style="height: 200px;"
                  class="form-control"
                  readonly
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <label>{{ $t('possible_answer') }}</label>
                <textarea
                  v-model="postLessonFormData.possible_answer"
                  style="height: 200px;"
                  class="form-control"
                  readonly
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <label>{{ $t('student_answer') }}</label>
                <textarea
                  v-model="postLessonFormData.user_answer"
                  style="height: 200px;"
                  class="form-control"
                  readonly
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('status')"
                  rules="required"
                >
                  <label>{{ $t('status') }}</label>
                  <v-select
                    v-model="postLessonFormData.status"
                    :options="statusOptions"
                    class="w-100"
                    :reduce="val => val.id"
                    label="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <label>{{ $t('correction_and_remarks') }}</label>
                <textarea
                  v-model="postLessonFormData.correction_and_remarks"
                  style="height: 200px;"
                  class="form-control"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

  </div>
</template>

<script>

import {
  required,
} from '@validations'

import {
  BForm,
  BCard,
  BTable,
  BPagination,
  BOverlay,
  BLink,
  BModal,
  BRow,
  BCol,
  BFormGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import PostLesson from '@/models/PostLesson'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import SharedPostLessonFilters from '@/views/Shared/Tutor/PostLesson/Filter.vue'

export default {
  name: 'PostLessonsWriting',
  components: {
    SharedPostLessonFilters,
    BForm,
    BCard,
    BTable,
    BPagination,
    BOverlay,
    BLink,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      fields: [
        'date',
        'student_name',
        'content_name',
        'status',
        'actions',
      ],
      statusOptions: [
        { id: 'Pending', name: 'Pending' },
        { id: 'Return', name: 'Return' },
        { id: 'Complete', name: 'Complete' },
      ],
      postLessonFormData: {
        id: '',
        user_answer: '',
        correction_and_remarks: '',
        question: '',
        possible_answer: '',
        status: '',
      },
      items: [],
      loading: true,
      perPage: 10,
      totalPage: 0,
      filter: {
        date: typeof this.$route.query.date !== 'undefined' ? this.$route.query.date : '',
        status: typeof this.$route.query.status !== 'undefined' ? this.$route.query.status : '',
        content_name: typeof this.$route.query.content_name !== 'undefined' ? this.$route.query.content_name : '',
        page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1,
      },
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData(page = 1) {
      this.loading = true
      const searchParams = {}
      searchParams.page = page
      searchParams.type = 'writing'
      Object.keys(this.filter).forEach(e => {
        if (e === 'level') {
          if (parseInt(this.filter[e], 0) > 0) {
            searchParams[e] = parseInt(this.filter[e], 0)
          }
        } else if (this.filter[e] !== null && this.filter[e].length > 0) {
          searchParams[e] = this.filter[e]
        }
      })
      this.$router.replace({ query: searchParams })
      const response = await PostLesson.list(searchParams)
      this.items = response.data.data
      this.totalPage = response.data.meta.pagination.total
      this.perPage = response.data.meta.pagination.per_page
      this.filter.page = response.data.meta.pagination.current_page
      this.loading = false
    },
    postLessonResetFormData() {
      this.postLessonFormData = {
        id: '',
        user_answer: '',
        correction_and_remarks: '',
        status: '',
      }
    },
    async viewLesson(data) {
      this.postLessonResetFormData()

      try {
        this.postLessonFormData.id = data.id
        this.postLessonFormData.status = data.status
        this.postLessonFormData.user_answer = data.user_answer
        this.postLessonFormData.correction_and_remarks = data.correction_and_remarks
        this.postLessonFormData.question = data.question
        this.postLessonFormData.possible_answer = data.possible_answer
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        })
      }

      this.$refs['view-post-lesson-modal'].show()
    },
    postLessonHandle(e) {
      // Prevent modal from closing
      e.preventDefault()
      // Trigger submit handler
      this.postLessonHandleSubmit()
    },
    async postLessonHandleSubmit() {
      const validator = await this.$refs.simpleRules.validate()

      if (validator) {
        try {
          const response = await PostLesson.update(this.postLessonFormData)

          this.$swal({
            title: 'Success!',
            text: response.data,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.loadData()
          this.$refs['view-post-lesson-modal'].hide()
          this.postLessonResetFormData()
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: e.message,
            },
          })
        }
      }
    },
  },

}
</script>

<style lang="scss">

</style>
